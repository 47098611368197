.inputSearch {
  border-radius: 0 !important;
  border-color: #ffffff5e !important;
  background-color: #ffffff5e !important;
  color: #fff !important;
}

.inputSearch::placeholder {
  color: #fff !important;
  font-family: "Poppins";
  font-weight: 300;
}

.searchSpace {
  padding: 15px;
  background-color: rgb(244 97 46);
}

.searchSpace h4 {
  color: rgb(255 255 255);
  font-family: "Poppins";
}

.searchButton
{
  border-radius: 0 !important;
  font-weight: 700 !important;
  color: rgb(255 255 255) !important;
  background-color: rgb(255 255 255 / 0%) !important;
  border: 2px solid rgb(255 255 255) !important;
}

.searchButton:hover{
  color: rgb(244 97 46) !important;
  background-color: rgb(255 255 255) !important;
}