.inputStyle {
  height: 100% !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  padding-left: 60px !important;
  border: none !important;
}

.inputStyle:focus {
  box-shadow: none !important;
}

.iconStyle {
  position: absolute;
  top: 49%;
  left: 10%;
  transform: translate(-50%, -50%);
  color: #92989e;
}

.formGroup {
  margin: 0;
  height: 100%;
  position: relative;
}

.btnSearch{
  height: 100% !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  background-color: rgb(252 252 252) !important;
  border-color: rgb(240 101 36);
  border: 2px solid rgb(240 101 36) !important;
  color: rgb(240 101 36) !important;
}

.btnSearch:hover{
  background-color: rgb(240 101 36) !important;
  color: rgb(252 252 252) !important;
}