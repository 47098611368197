@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Fjalla+One&display=swap&family=Poppins:wght@300;400;500;700&display=swap");

.parentDestinationMain {
  background-color: #000;
  width: 100%;
  height: 300px;
  border-radius: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
  -moz-box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
  box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
}

.destinationMain {
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: "Dancing Script", cursive;
  font-size: 40px;
}

.parentDestination {
  background-color: #000;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
  -moz-box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
  box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
  overflow: hidden;
}

.parentDestination:hover .destinationBackground{
  transform: scale(1.2);
  transition: all .5s;
}

.destination {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
}

.offerprice{
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgb(240 101 36);
  padding-top: 5px !important;
  padding: 15px;
  font-family: "Poppins";
  border-bottom-left-radius: 17px;
  text-align: center;
}

.offerpriceP{
  margin: 0 !important;
}

.offerpriceSpan{
  font-weight: 700;
}

.destinationBackground{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .5s;
}

.destinationBackground::before {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.33);
  height: 100%;
}