.topTitre {
  color: #3fd0d4;
  font-family: "Dancing Script";
  font-size: 30px;
}

.title {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 50px;
}

.description {
  font-family: "Poppins";
  font-weight: 300;
}
