@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Poppins:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Fjalla+One&display=swap&family=Poppins:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TopHeaderStyle_topHeader__1aJOo {
  padding: 10px !important;
  background: #000;
  color: #fff;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  /*text-transform: uppercase;*/
}

.header_header__3YLiR {
  font-family: "Poppins";
  font-weight: 500;
  color: #000;
}
.header_navLink__2s9f1 {
  padding: 0 30px;
}

.header_navLink__2s9f1 a {
  color: #000 !important;
}
.destination_parentDestinationMain__2O44O {
  background-color: #000;
  width: 100%;
  height: 300px;
  border-radius: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
}

.destination_destinationMain__2OQ5p {
  color: #fff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  font-family: "Dancing Script", cursive;
  font-size: 40px;
}

.destination_parentDestination__2w3rR {
  background-color: #000;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  box-shadow: 0px 0px 11px 0px rgba(176, 176, 176, 1);
  overflow: hidden;
}

.destination_parentDestination__2w3rR:hover .destination_destinationBackground__1dKw9{
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: all .5s;
}

.destination_destination__3ngSy {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
}

.destination_offerprice__3E9Mo{
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgb(240 101 36);
  padding-top: 5px !important;
  padding: 15px;
  font-family: "Poppins";
  border-bottom-left-radius: 17px;
  text-align: center;
}

.destination_offerpriceP__iQiVP{
  margin: 0 !important;
}

.destination_offerpriceSpan__1tuHr{
  font-weight: 700;
}

.destination_destinationBackground__1dKw9{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .5s;
}

.destination_destinationBackground__1dKw9::before {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.33);
  height: 100%;
}
.mainDestination_topTitre__3md7V {
  color: #3fd0d4;
  font-family: "Dancing Script";
  font-size: 30px;
}

.mainDestination_title__3NxcG {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 50px;
}

.mainDestination_description__MpNkJ {
  font-family: "Poppins";
  font-weight: 300;
}

.nav-tabs {
  background-color: #dfe6e9;
}

.nav-tabs a.active {
  color: #000 !important;
  border: none;
}

.nav-tabs a {
  font-size: 14px;
  padding: 29px 44px;
  color: #636e72;
}

.nav-tabs a:hover {
  color: #636e72;
}

.ContainerTabs {
  position: relative;
  z-index: 100;
  margin-top: -80px;
}

.spanTabTitle {
  position: relative;
  top: 2px;
}

.tab-pane {
  padding: 50px;
}

.tab-pane h4.tabTitleContent {
  font-family: "Poppins";
  font-weight: 700;
  float: left;
  padding-right: 20px;
}

.tab-pane p.tabPriceContent {
  float: left;
  position: relative;
  top: 4px;
  font-family: "Poppins";
  font-weight: 700;
}

.tab-pane p.tabPriceContent span{
  /* color: rgb(63, 208, 212); */
  color: rgb(240, 101, 36);
}

.tab-pane p.tabPriceContent small {
  color: #636e72;
  padding-left: 10px;
  position: relative;
}

.tab-pane p.tabDescriptionContent {
  font-family: "Poppins";
}

.tab-pane div.tabInformationContentTitle {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
}

.tab-pane div.tabInformationContentValue {
  font-family: "Poppins";
  font-size: 14px;
}

.galrieTitre {
  font-family: "Poppins";
  font-weight: 700;
}

.tabTitleProgramme {
  font-family: "Poppins";
  font-weight: 700;
}

.nbrJour {
  background-color: #0d6aaf;
  text-align: center;
  padding: 10px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
}

.jourTitle {
  padding-top: 10px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
}

span.separator {
  height: 100%;
  position: absolute;
  z-index: -1;
  width: 50px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

span.separator span.separatorContent {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 100%;
  border-right: 2px dashed #0d6aaf;
  z-index: 8;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tabDescriptionReserver {
  font-family: "Poppins";
  font-weight: 400;
}

.calendarYearMonthDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  background: #0d6aaf;
  color: #fff;
}

.calendarYearMonthDiv .calendarYearMonthLabel {
  font-family: "Poppins";
  font-weight: 700;
}

.table thead th {
  border: none !important;
}

.table td,
.table th {
  border: none !important;
}

.hasTarif span {
  padding: 5px 10px;
  background-color: #0d6aaf;
  border-radius: 50%;
  color: #fff;
}

.calendar-day {
  font-family: "Poppins";
  font-weight: 400;
}

.hasTarif span {
  cursor: pointer;
}


.readMoreDiv{
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 43%);
}

.readMoreSpan{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  border: 2px solid #fff;
  padding: 15px;
  font-family: Poppins;
  font-weight: 700;
}

.linkReadMore:hover .readMoreDiv{
  display: block;
}
.offers_containerGlob__1Mb2a {
  background-color: rgba(248, 248, 248, 1);
  padding-top: 50px;
  padding-bottom: 50px;
}

.offers_topTitle__3Y67r {
  color: rgba(63, 208, 212, 1);
  font-family: "Dancing Script";
  font-size: 30px;
}

.offers_title__3l2Nx {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 50px;
}

.offers_description__3UIye {
  font-family: "Poppins";
  font-weight: 300;
}

.offers_offerVueParent__3OPYd {
  width: 100%;
  height: 520px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.offers_offerVueParent__3OPYd::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #00000073;
}

.offers_offerTitleParent__uJ3WT {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.offers_offerTitle__cHF84 {
  color: #fff;
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 700;
}

.offers_offerTopTitle__3T5iS {
  color: #fff;
  font-family: "Dancing Script";
  font-size: 30px;
}

.offers_offerTab__4gEx5 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 19px;
  padding: 15px 20px;
  text-transform: uppercase;
}

.offers_offerTab__4gEx5.offers_active__2-B4r {
  color: #000;
}

.offers_checkoutTitles__Bb4mG {
  color: #0d6aaf;
  font-family: "Poppins";
  font-weight: 700;
}

.offers_checkoutTitlesSpan__2NTFj {
  padding: 11px 20px;
  margin-right: 10px;
  background: #0d6aaf;
  color: #fff;
  border-radius: 50%;
}

.offers_formulaireFacturation__1mQ0j {
  font-family: "Poppins";
}

.offers_spaceShowVoyageurTitle__9QLkL {
  font-family: "Poppins";
}

.offers_btnReservation__21x5i {
  width: 100%;
  background: #0d6aaf;
  border-color: #0d6aaf;
  font-family: "Poppins";
}

.offers_btnReservation__21x5i:hover {
  background: #0d6aaf;
  border-color: #0d6aaf;
}

.invalid-feedback {
  font-family: "Poppins";
  font-weight: 300;
}

.search_inputStyle__2jdP9 {
  height: 100% !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  padding-left: 60px !important;
  border: none !important;
}

.search_inputStyle__2jdP9:focus {
  box-shadow: none !important;
}

.search_iconStyle__1LCjN {
  position: absolute;
  top: 49%;
  left: 10%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #92989e;
}

.search_formGroup__3fQgV {
  margin: 0;
  height: 100%;
  position: relative;
}

.search_btnSearch__2f3r3{
  height: 100% !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  background-color: rgb(252 252 252) !important;
  border-color: rgb(240 101 36);
  border: 2px solid rgb(240 101 36) !important;
  color: rgb(240 101 36) !important;
}

.search_btnSearch__2f3r3:hover{
  background-color: rgb(240 101 36) !important;
  color: rgb(252 252 252) !important;
}
.searchComponent_inputSearch__35Qkr {
  border-radius: 0 !important;
  border-color: #ffffff5e !important;
  background-color: #ffffff5e !important;
  color: #fff !important;
}

.searchComponent_inputSearch__35Qkr::-webkit-input-placeholder {
  color: #fff !important;
  font-family: "Poppins";
  font-weight: 300;
}

.searchComponent_inputSearch__35Qkr:-ms-input-placeholder {
  color: #fff !important;
  font-family: "Poppins";
  font-weight: 300;
}

.searchComponent_inputSearch__35Qkr::placeholder {
  color: #fff !important;
  font-family: "Poppins";
  font-weight: 300;
}

.searchComponent_searchSpace__2THKz {
  padding: 15px;
  background-color: rgb(244 97 46);
}

.searchComponent_searchSpace__2THKz h4 {
  color: rgb(255 255 255);
  font-family: "Poppins";
}

.searchComponent_searchButton__2EIRY
{
  border-radius: 0 !important;
  font-weight: 700 !important;
  color: rgb(255 255 255) !important;
  background-color: rgb(255 255 255 / 0%) !important;
  border: 2px solid rgb(255 255 255) !important;
}

.searchComponent_searchButton__2EIRY:hover{
  color: rgb(244 97 46) !important;
  background-color: rgb(255 255 255) !important;
}
.FormsStyle_buttonConfirm__34JUR{background:#e67e22 !important;border-color:#f39c12 !important;width:100%;font-family:"Poppins" !important;font-weight:500 !important;border-radius:0 !important}.FormsStyle_spanNbr__3uqNQ{background-color:#f39c12;color:#fff;padding:5px 15px;border-radius:100%}.FormsStyle_formCheckOut__3el5P{font-family:"Poppins" !important}.FormsStyle_formInputs__15foF{border-radius:0 !important;border-color:#f39c12 !important;color:#f39c12 !important}.FormsStyle_formInputs__15foF:focus{box-shadow:0 0 0 .2rem rgba(243,156,18,.3215686275) !important}.FormsStyle_formInputs__15foF::-webkit-input-placeholder{color:#f39c12 !important}.FormsStyle_formInputs__15foF:-ms-input-placeholder{color:#f39c12 !important}.FormsStyle_formInputs__15foF::placeholder{color:#f39c12 !important}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.container {
  max-width: 1300px !important;
}

.top-header {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

a:hover {
  text-decoration: none;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader{
  background-color: #f1f2f3;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 90px;
  background-position: 50%;
  z-index: 9999999999;
  position: fixed;
  width: 100%;
}
